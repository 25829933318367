<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else class="mt-4">
		<div class="tabs-container-large pb-5">
			<div v-if="steps" class="tabs-wrapper">
				<CTabs
					:active-tab="activeTab"
					@update:activeTab="handleUpdateTab"
				>
					<CTab
						v-for="(step, key) in steps"
						:key="key"
						:title="`Step ${key}: ${step.title}`"
						:disabled="step.disabled"
					>
						<div class="main-wrapper-large">
							<component
								:is="step.component"
								:default-data="step.formData"
								:flash-sale-type="flashSaleType"
								@update:is-valid="handleValidChange(key, $event)"
							/>
						</div>
					</CTab>
				</CTabs>
			</div>
			<div class="navigation-panel">
				<div class="main-wrapper-large">
					<CRow>
						<CCol
							lg="12"
							class="d-flex justify-content-end align-items-center"
						>
							<span class="mr-4">Step {{ currentStep }}/{{ totalStep }}</span>
							<CButton
								color="tertiary"
								class="transparent mr-3"
								@click.prevent="$router.push({ name: 'FlashSales'})"
							>
								Cancel
							</CButton>
							<CButton
								type="button"
								class="transparent"
								color="primary"
								data-test-id="button"
								:disabled="!isEnableNextStep || isCreating"
								@click="handleClickNextStep"
							>
								{{ currentStep === totalStep ? 'Create' : 'Next' }}
							</CButton>
						</CCol>
					</CRow>
				</div>
			</div>
		</div>
		<BaseModalDuplicatePeriod
			ref="modal-duplicate-period"
			title="Duplicate period"
			description="There is another flash sale already active on the selected period. If you want to create this flash sale please change period or inactive status."
			primary-button-text="OK"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FlashSaleStepGeneralInfo from '@/components/FlashSaleStepGeneralInfo.vue';
import FlashSaleStepProductList from '@/components/FlashSaleStepProductList.vue';
import FlashSaleStepVisibility from '@/components/FlashSaleStepVisibility.vue';
import BaseModalDuplicatePeriod from '@/components/BaseModalDuplicatePeriod.vue';
import { FLASHSALE_LABEL_TITLE_TABS } from '../enums/flashSales';
import { transformedFlashSaleDataToAPI, transformedFlashSaleInfoToDataMultipleStep } from '../assets/js/transform/flashsales';
import { pathOr } from '../assets/js/helpers';

export default {
	name: 'FlashSaleCreate',
	components: {
		BaseModalDuplicatePeriod,
	},

	data() {
		return {
			flashSaleType: FLASHSALE_LABEL_TITLE_TABS.GENERAL,
			currentStep: 1,
			totalStep: 0,
			steps: null,
			isLoading: true,
			isCreating: false,
			isDuplicated: false,
		};
	},

	computed: {
		...mapState('flashSales', {
			create: 'create',
			edit: 'edit',
		}),

		activeTab() {
			return this.currentStep - 1;
		},

		isEnableNextStep() {
			const nextStep = this.currentStep + 1;
			if (this.currentStep === this.totalStep) {
				return this.steps[this.currentStep].valid;
			}

			return !this.steps[nextStep].disabled && this.steps[this.currentStep].valid;
		},
	},

	async created() {
		const { ref: flashSaleId = null } = this.$route.query;

		this.initialStep();

		if (flashSaleId) {
			await this.getFlashSale(flashSaleId);
			await this.extractDataToStep();
		}

		this.isLoading = false;
	},

	methods: {
		...mapActions({
			createFlashSale: 'flashSales/createFlashSale',
			getFlashSale: 'flashSales/getFlashSale',
			showMultipleErrorsToast: 'toast/showMultipleErrorsToast',
		}),

		close() {
			this.isDuplicated = false;
		},
		initialStep() {
			this.steps = {
				1: {
					title: FLASHSALE_LABEL_TITLE_TABS.GENERAL_INFO,
					valid: false,
					disabled: false,
					component: FlashSaleStepGeneralInfo,
					formData: null,
				},
				2: {
					title: FLASHSALE_LABEL_TITLE_TABS.PRODUCT_LIST,
					valid: false,
					disabled: true,
					component: FlashSaleStepProductList,
					formData: {},
				},
				3: {
					title: FLASHSALE_LABEL_TITLE_TABS.VISIBILITY,
					valid: false,
					disabled: true,
					component: FlashSaleStepVisibility,
					formData: {},
				},
			};

			this.totalStep = Object.keys(this.steps).length;
		},
		async handleClickNextStep() {
			if (this.currentStep === this.totalStep) {
				await this.submitForm();

				return;
			}

			// Handle tab's disabled status
			if (this.isEnableNextStep) {
				this.currentStep += 1;
			}
		},
		handleUpdateTab(tabIndex) {
			this.currentStep = tabIndex + 1;
		},

		handleValidChange(key, value) {
			if (!key) {
				return;
			}

			const { valid, data: formData } = value;
			this.$set(this.steps, key, { ...this.steps[key], valid, formData });
			// Toggle disabled status for next tab
			const nextIndex = Number(key) + 1;
			if (nextIndex <= this.totalStep) {
				this.$set(this.steps, (nextIndex), { ...this.steps[nextIndex], disabled: !valid });
			}

			for (let index = nextIndex; index <= this.totalStep; index++) {
				if (!valid) {
					// if next tab (n+1) is disabled, the rest must be disabled too.
					this.$set(this.steps, (index), { ...this.steps[index], disabled: true });
				} else if (this.steps[index].valid) {
					// if next tab (n+1) is enabled and already valid, The next two tab (n+2) should be enabled too.
					const nextTwoIndex = index + 1;
					if (nextTwoIndex <= this.totalStep) {
						this.$set(this.steps, (nextTwoIndex), { ...this.steps[nextTwoIndex], disabled: false });
					}
				}
			}
		},
		async submitForm() {
			this.isCreating = true;

			const postData = transformedFlashSaleDataToAPI({ steps: this.steps });
			try {
				await this.createFlashSale(postData);
				this.$router.push({ name: 'FlashSaleList' });
			} catch (error) {
				const statusCode = pathOr(null, ['response', 'status'])(error);
				const isErrorOverlap = !!(pathOr(false, ['response', 'data', 'errors', 'date_time', 0])(error));

				if (statusCode === 422) {
					if (isErrorOverlap) {
						this.isDuplicated = true;
						this.$refs['modal-duplicate-period'].open();
					} else {
						this.showMultipleErrorsToast(error);
					}
				}
			} finally {
				this.isCreating = false;
			}
		},
		async extractDataToStep() {
			if (!this.edit.data) { return; }
			const flashSaleData = await transformedFlashSaleInfoToDataMultipleStep(this.edit.data);

			if (flashSaleData) {
				// Delete period and start - end datetime from step 1 when duplicate flash sale
				delete flashSaleData.steps[1].formData.startDate;
				delete flashSaleData.steps[1].formData.startTime;
				delete flashSaleData.steps[1].formData.endDate;
				delete flashSaleData.steps[1].formData.endTime;
				delete flashSaleData.steps[1].formData.period;
				delete flashSaleData.steps[1].formData.slug;

				// Initial value in each steps
				Object.keys(this.steps).forEach((step) => {
					this.$set(this.steps, (step), {
						...this.steps[step],
						formData: flashSaleData.steps[step].formData,

						// Set valid to false from step 1 since we remove datetime from initial value
						valid: step !== 1,
					});
				});
			}
			this.isLoading = false;
		},
	},
};
</script>

<style lang="scss" scoped>
	.navigation-panel {
		position: fixed;
		left: rem(56);
		right: 0;
		bottom: 0;
		padding: rem(12) 0;
		box-shadow: 0 1px 2px 0 $color-gray-07, 0 4px 16px 0 $color-gray-15;
		background-color: $color-white;
		z-index: 9;

		@media (max-width: 991.98px) {
			left: 0;
		}

		::v-deep button {
			min-width: rem(80);
		}
	}
</style>
